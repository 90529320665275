@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins";
  font-weight: 300;
  font-style: normal;
  src: url("/fonts/Poppins/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  font-style: normal;
  src: url("/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 600;
  font-style: normal;
  src: url("/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  src: url("/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

body {
  font-family: "Poppins", sans-serif;
  line-height: 2rem;
}

h1,
h2,
h3,
h4 {
  font-family: "Poppins", sans-serif;
  letter-spacing: 1%;
  line-height: 3rem;
}

h1 {
  font-size: 2.6rem;
  font-weight: 700;
}

h2 {
  font-size: 2.4rem;
  font-weight: 500;
}

h3 {
  font-size: 2rem;
  font-weight: 500;
}

h4 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: auto;
}

@layer components {
  .section-container {
    @apply max-w-8xl mx-auto lg:px-16;
  }
}
